<template>
	<div>
		<!-- 头部logo -->
		<moveHeadLogo @navigation="navigation"></moveHeadLogo>
		
		<!-- 头部导航 -->
		<moveNavigation :headTitle="headTitle" :headImg="headImg"></moveNavigation>
		
		<!-- 信息 -->
		<div class="intenationalOneM">
			<el-row class="intenationalOneM-box">
				<template v-if="!!oneData">
					<span v-html="oneData.bodyContent" style="white-space: normal;">{{oneData.bodyContent}}</span>
				</template>
				<el-image src="/index/across.png" class="acrossIntenM-img"></el-image>
			</el-row>
			<!-- 切换 -->
			<el-row class="programsIntenM-box">
				<div :class="[this.value==1?'theaterIntenM-yes':'theaterIntenM-no']" @click="forum">
					INTERNATIONAL THEATRE FORUM
				</div>
				<div :class="[this.value==2?'theaterIntenM-yes':'theaterIntenM-no']" @click="arts">
					INTERNATIONAL ALLIANCE
				</div>
				<div :class="[this.value==3?'theaterIntenM-yes':'theaterIntenM-no']" @click="partnerships">
					STRATEGIC COLLABORATIONS
				</div>
				<div :class="[this.value==4?'theaterIntenM-yes':'theaterIntenM-no']" @click="partners">
					OUR PARTNERS
				</div>
			</el-row>
			<!-- forum数据 -->
			<el-row id="forumScoll">
				<el-row class="newsIntenM-databox">
					<el-col :span="12" v-for="(item,id) in ListData" :key="id">
						<div @click="newsDetails(item)">
							<el-row class="evenas-data-extend">
								<img :src="eventsNotLoadImage" :onload="onLoadImg(item.pictureUrl)" class="eventsdata-img"></img>
								<el-image class="evenasdata-coverageimg" src="/home/homeEventMcoverage.png"></el-image>
								<el-image class="evenasdata-logoimg" src="/home/logo.png"></el-image>
								<div class="evenasdata-titleMessage">
									<el-row class="evenasdata-title">{{item.title}}</el-row>
									<template v-if="item.bodyContent">
										<el-row v-html="item.bodyContent" class="evenasdata-message">{{item.bodyContent}}</el-row>
									</template>
								</div>	
								<el-row class="evenasdata-date">
									<el-image class="theaterIntenM-chainimg" src="/home/chain.png"></el-image>
								</el-row>
							</el-row>
						</div>
					</el-col>
				</el-row>
				<!-- 按钮 -->
				<el-row>
					<button v-if="loading" class="productionsIntenM-btn" @click="load">Load More</button>
				</el-row>
			</el-row>
			<!-- arts数据 -->
			<el-row id="artsScoll">
				<div v-for="(item,id) in polyListData" :key="id" class="polyDataIntenM"  @click="newsDetails(item)">
					<el-row class="productionsIntenM-imgbox">
						<img :src="proNotLoadImage" :onload="onLoadImg(item.pictureUrl)" class="productionsIntenM-img"></img>
					</el-row>
					<el-row class="productionsIntenM-databox">
						<el-col :span="1">
							<el-image src="/index/vertical.png" class="verticalIntenM-img"></el-image>
						</el-col>
						<el-col :span="22" :offset="1">
							<el-row class="productionsIntenM-title">
								{{item.title}}
							</el-row>
							<template v-if="item.bodyContent">
								<el-row v-html="item.bodyContent" class="productionsIntenM-message">
									{{item.bodyContent}}
								</el-row>
							</template>
						</el-col>
					</el-row>
				</div>
				<!-- 按钮 -->
				<el-row>
					<button v-if="polyloading" class="productionsIntenM-btn" @click="polyLoad">Learn More</button>
				</el-row>
			</el-row>
			<!-- partnerships数据 -->
			<el-row id="partnershipsScoll">
				<el-row class="newsIntenM-databox">
					<el-col :span="12" v-for="(item,id) in shipsListData" :key="id">
						<div @click="newsDetails(item)">
							<el-row class="evenas-data-extend">
								<img :src="eventsNotLoadImage" :onload="onLoadImg(item.pictureUrl)" class="eventsdata-img"></img>
								<el-image class="evenasdata-coverageimg" src="/home/homeEventMcoverage.png"></el-image>
								<el-image class="evenasdata-logoimg" src="/home/logo.png"></el-image>
								<div class="evenasdata-titleMessage">
									<el-row class="evenasdata-title">{{item.title}}</el-row>
									<template v-if="item.bodyContent">
										<el-row v-html="item.bodyContent" class="evenasdata-message">{{item.bodyContent}}</el-row>
									</template>
								</div>	
								<el-row class="evenasdata-date">
									<el-image class="theaterIntenM-chainimg" src="/home/chain.png"></el-image>
								</el-row>
							</el-row>
						</div>
					</el-col>
				</el-row>
				<!-- 按钮 -->
				<el-row>
					<button v-if="loading" class="productionsIntenM-btn" @click="load">Load More</button>
				</el-row>
			</el-row>
			<!-- logo数据 -->
			<el-row id="ourScoll" class="logoIntenM-imgbox">
				<el-row v-if="isImg">
					<el-col :span="8" v-for="(item, index) in slideshowImg"
                        :key="index">
						<el-image :src="item" class="logoIntenM-img"></el-image>
					</el-col>
				</el-row>
				<el-row v-else>
					<el-row>
						<el-image src="/index/nodata.png" class="error-img"></el-image>
					</el-row>
					<el-row class="error-message">
						Empty data
					</el-row>
				</el-row>
			</el-row>
		</div>
		
		<!-- 菜单导航 -->
		<moveMeauDialog :meauValue="navigationvalue" @meauFn="menu"></moveMeauDialog>
		
		<!-- 底部导航 -->
		<moveBottomNavigation></moveBottomNavigation>
	</div>
</template>

<script>
 
// 图片查询接口
import { selectPictureListById , selectContentListById } from '@/api/index.js'

	// 头部组件
	import moveHeadLogo from '@/components/move/moveHeadLogo.vue'
	// 菜单导航
	import moveMeauDialog from '@/components/move/moveMeauDialog.vue'
	// 头部导航
	import moveNavigation from '@/components/move/moveNavigation.vue'
	// 底部导航
	import moveBottomNavigation from '@/components/move/moveBottomNavigation.vue'
	export default
	{
		data()
		{
			return{
				// 头部导航标题
				headTitle:'INTERNATIONAL COLLABORATIONS',
				// 头部导航图片
				headImg:'/index/international.png',
				value:1,
				// 导航值
				navigationvalue:false,
				
				// 一级forums
				count:4,// 默认显示条数
				loading:true,// 加载按钮
				newList:[],//数据
				
				// 二级arts
				polyCount:2,// 默认显示条数
				polyloading:true,// 加载按钮
				polyList:[],//数据
				
				// 三级ships
				shipsCount:4,// 默认显示条数
				shipsloading:true,// 加载按钮
				shipsList:[],//数据

				oneData:{},
				slideshowImg:[],
				isImg:true,
				eventsNotLoadImage: "/Mimage/335_252.jpg", //事件图片未加载出来
				proNotLoadImage: "/Mimage/664_290.jpg", //产品图片未加载出来
			}
		},
		components:
		{
			moveHeadLogo,
			moveMeauDialog,
			moveNavigation,
			moveBottomNavigation,
		},
		computed:
		{
			// 计算属性
			ListData()
			{
				let data=[];
				if(this.newList.length>4)
				{
					data=this.newList.slice(0,this.count)
					return data;
				}
				else
				{
					data=this.newList;
					this.loading=false;
					return data;
				}
			},
			polyListData()
			{
				let data=[];
				if(this.polyList.length>2)
				{
					data=this.polyList.slice(0,this.polyCount)
					return data;
				}
				else
				{
					data=this.polyList;
					this.polyloading=false;
					return data;
				}
			},
			shipsListData()
			{
				let data=[];
				if(this.shipsList.length>4)
				{
					data=this.shipsList.slice(0,this.shipsCount)
					return data;
				}
				else
				{
					data=this.shipsList;
					this.shipsloading=false;
					return data;
				}
			}
		},
		created()
		{
			this.oneList()
			this.imgList()
			this.getForumList();
			this.getArtsList();
			this.getPartnershipsList();
		},
		mounted()
		{
			window.addEventListener('scroll', this.scrollEvent);
		},
		methods:
		{
			// 头部传来的数据
			navigation(v)
			{
				this.navigationvalue=v;
				console.log(this.navigationvalue,'头部传递菜单导航')
			},
			// 遮罩层传回来的数据
			menu(v)
			{
				this.navigationvalue=v;
			},
			
			// 获取第一条数据
			oneList()
			{
				selectContentListById(
				{
				  chnanelType: 1,
				  isIndex: 0,
				  moduleType: 'MODULE_INTERNATIONAL'
				})
				.then((response)=>
				{
					this.oneData=response.data.data[0];
				})
			},
			// 获取FORUM数据
			getForumList()
			{
				selectContentListById(
				{
				  chnanelType: 2,
				  isIndex: 0,
				  moduleType: 'MODULE_FORUM'
				})
				.then((response)=>
				{
					this.loading=true;
					this.newList=response.data.data;
				})
			},
			// 获取Arts数据
			getArtsList()
			{
				selectContentListById(
				{
				  chnanelType: 2,
				  isIndex: 0,
				  moduleType: 'MODULE_ARTS'
				})
				.then((response)=>
				{
					this.polyloading=true;
					this.polyList=response.data.data;
				})
			},
			// 获取Partnerships数据
			getPartnershipsList()
			{
				selectContentListById(
				{
				  chnanelType: 2,
				  isIndex: 0,
				  moduleType: 'MODULE_PARTNERSHIPS'
				})
				.then((response)=>
				{
					this.shipsloading=true;
					this.shipsList=response.data.data;
				})
			},
			// 获取合作品牌图片
			imgList()
			{
				this.isImg=true;
				selectPictureListById({chnanelType:2,picturetype:5})
				.then((response)=>
				{
					response.data.data.map((v)=>
					{
						this.slideshowImg.push(v.pictureUrl);
					})
					if(this.slideshowImg.length==0)
					{
						this.isImg=false;
					}
				})
			},
			
			// 获取css高度
			getWindowData()
			{
				var PageId = document.querySelector(this.windowData)
				window.scrollTo(
				{
					'top': PageId.offsetTop,
					'behavior': 'smooth'
				})
			},
			
			forum()
			{
				this.value=1;
				this.windowData='#forumScoll'
				this.getWindowData();
			},
			arts()
			{
				this.value=2;
				this.windowData='#artsScoll'
				this.getWindowData();
			},
			partnerships()
			{
				this.value=3;
				this.windowData='#partnershipsScoll'
				this.getWindowData();
			},
			partners()
			{
				this.value=4;
				this.windowData='#ourScoll'
				this.getWindowData();
			},
			
			// 滑动事件
			scrollEvent()
			{
				// 获取视窗高度
				let domHight = document.body.offsetHeight;
				// dom滚动位置
				let scrollTop =
				  window.scrollY ||
				  document.documentElement.scrollTop ||
				  document.body.scrollTop;
				var PageTdForum=document.querySelector('#forumScoll')
				var PageTdArts=document.querySelector('#artsScoll')
				var PageTdPartnerships=document.querySelector('#partnershipsScoll')
				var PageTdOur=document.querySelector('#ourScoll')
				if(scrollTop>=PageTdForum.offsetTop)
				{
					this.value=1;
				}
				if(scrollTop>=PageTdArts.offsetTop)
				{
					this.value=2;
				}
				if(scrollTop>=PageTdPartnerships.offsetTop)
				{
					this.value=3;
				}
				if(scrollTop>=PageTdOur.offsetTop)
				{
					this.value=4;
				}
			},
			
			// 一级 - 加载 - 收起
			load()
			{
				this.count+=4;
				if(this.count>=this.newList.length)
				{
					this.loading=false
				}
			},
			
			// 二级 - 加载 - 收起
			polyLoad()
			{
				this.polyCount+=2;
				if(this.polyCount>=this.polyList.length)
				{
					this.polyloading=false
				}
			},
			// 三级 - 加载 - 收起
			shipsLoad()
			{
				this.shipsCount+=4;
				if(this.shipsCount>=this.shipsList.length)
				{
					this.shipsloading=false
				}
			},
		
			// 数据详情
			newsDetails(item)
			{
				console.log(item)
				localStorage.setItem('Details',JSON.stringify(item));
				this.$router.push(
				{
					path:'/moveNoticesDetails',
					query:
					{
						item:item,
						headTitle:'INTERNATIONAL COLLABORATIONS',
						headImg:'/index/international.png',
					}
				})
			},
			// 图片加载完成时触发
			onLoadImg(imgSrc) 
			{
				return 'this.src='+'"'+imgSrc+'";this.οnlοad=null';
			},
		}
	}
</script>

<style scoped>
	/* 封装css */
	@import '../../assets/scrollBar.css';
	@import '../../assets/MoveInterTwoDate.css';
	.intenationalOneM{
		width: 96%;
		margin-left: 2%;
	}
	.intenationalOneM-box{
		width: 100%;
		background-color: #F7F4F8;
		border-radius: 10px;
		padding: 60px 20px 38px;
		position: relative;
		line-height: 30px;
		text-align: left;
		
		font-size: 20px;
		font-family: Roboto;
		color: #505050;
	}
	.intenationalOneM-box::v-deep img{
		max-width: 100%;
	}
	.polyDataIntenM{
		width:100%;
		height:600px;
		position:relative; 
		color:#505050;
	}
	.productionsIntenM-imgbox{ 
		width: 90%; 
		position:absolute; 
		border-radius: 20px;
		z-index: 34;
	} 
	.acrossIntenM-img{
		width: 103px;
		height: 5px;
		position: absolute;
		top: 30px;
		left: -10px;
	}
	.programsIntenM-box{
		white-space: nowrap;
		overflow-y: scroll;
		margin-top: 30px;
		font-size: 24px;
		color: #505050;
	}
	.theaterIntenM-yes{
		background-color: #FCD002;
		border: solid 1px #B4B7B6;
		display: inline-block;
		margin-right: 3%;
		padding: 21px 21px 20px 21px;
		border-radius: 10px;
	}
	.theaterIntenM-no{
		background-color: #F0EDF1;
		border: solid 1px #B4B7B6;
		display: inline-block;
		margin-right: 3%;
		padding: 21px 21px 20px 21px;
		border-radius: 10px;
	}
	.newsIntenM-databox{
		margin-top: 30px;
	}
	.newsIntenM-data{
		width: 95%;
		margin-right: 5%;
		position: relative;
		border: solid 1px #f5f5f5;
		margin-top: 20px;
	}
	.newsIntenM-data :hover{
		box-shadow: 1px 1px 20px #999;
	}
	.newsIntenM-data-extend :hover{
		box-shadow: none;
	}
	.newsdataIntenM-img{
		width: 100%;
		height: 350px;
	}
	.newsdataIntenM-coverageimg{
		width: 102%;
		height: 170px;
		position: absolute;
		bottom: -2px;
		left: -1%;
	}
	.newsdataIntenM-logoimg{
		width: 30px;
		height: 16px;
		position: absolute;
		bottom: 145px;
		left: 42%;
	}
	.newsdataIntenM-title{
		width: 96%;
		z-index: 99;
		position: absolute;
		bottom: 100px;
		font-size: 15px;
		text-align: center;
		margin-left: 2%;
		word-wrap:break-word;
		white-space: pre-wrap;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.newsdataIntenM-message{
		width: 95%;
		height: 100px;
		z-index: 99;
		position: absolute;
		bottom: 5px;
		font-size: 13px;
		text-align: center;
		margin-left: 2%;
		line-height: 17px;
		word-wrap:break-word;
		overflow: hidden;
		white-space: pre-wrap;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 5;
		-webkit-box-orient: vertical;
		background-color: plum;
	}
	.newsdataIntenM-message img{
		max-width: 100%;
	}
	.theaterIntenM-chainimg{
		width: 100px;
		height: 10px;
	}
	.productionsIntenM-btn{
		border: none;
		border-radius: 50px;
		margin-top: 10px;
		background-color: #FCD002;
		color: #505050;
		margin-bottom: 20px;
		width: 600px;
		height: 80px;
		font-size: 30px;
		color: #505050;
	}
	.productionsIntenM-nobtn{
		width: 30%;
		height: 40px;
		border: none;
		border-radius: 50px;
		font-size: 15px;
		margin-top: 30px;
		background-color: #CCC;
		color: #505050;
		margin-bottom: 20px;
	}
	.productionsIntenM-img{
		width: 640px;
		height: 360px;
		border-radius: 20px;
		float: left;
		z-index: 34;
		margin-top: 20px;
	}
	.productionsIntenM-databox{
		width:664px;
		height: 422px;
		position:absolute;
		right:0px;
		top: 210px;
		background-color: #F6F5F8;
		border-radius: 20px;
		padding: 190px 2% 0px 5%;
		text-align: left;
	}
	.productionsIntenM-databox::v-deep img{ max-width: 100%;}
	.productionsIntenM-title{
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		height: 59px;
		font-size: 30px;
		color: #505050;
		line-height: 30px;
	}
	.productionsIntenM-message{
		height: 150px;
		line-height: 20px;
		word-wrap:break-word;
		white-space: pre-wrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 5;
		-webkit-box-orient: vertical;
		font-size: 20px;
		color: #505050;
		line-height: 30px;
	}
	.verticalIntenM-img{
		width: 4px;
		height: 103px;
		position: absolute;
		bottom: 170px;
		left:32px;
		z-index: 99;
	}
	.logoIntenM-imgbox{
		margin-top: 20px;
	}
	.logoIntenM-img{
		width: 210px;
		height: 210px;
		margin-left: 30px;
		margin-right: 30px;
		border-radius:10px;
	}
	.error-img{
		width: 200px;
		height: 150px;
		margin-top: 100px;
	}
	.error-message{
		margin-top: 30px;
		font-size: 20px;
		color: #3B519D;
		margin-bottom: 50px;
	}
	#forumScoll{
		margin-bottom: 30px;
	}
</style>